
var Ui = {};
var config;
var gvl;
var onSave;
var rnd = 's' + (new Date().getTime()).toString(36) + Math.random().toString(36).slice(2);      
var Utils;

// html constant helpers
const FONT_FAMILY_RULE = 'font-family: Helvetica,Arial,FreeSans,sans-serif !important';
const INNERDIV_OPEN = `<div class="innerdiv_shbnr_cs_${rnd}">`;
const SWITCH_CHECKED_COLOR = '#7df';
const SWITCH_CHECKED_BORDER_COLOR = '#08c';
const EXPAND_ARROW = '<div class="expand_arrow_shbnr_cs_' + rnd + '"></div>';
const vendor_header = function(s) { return `
  <thead>
    <tr>
      <th colspan="4">
        &#9656 ${s}
      </th>
    </tr>
  </thead>
`;};

function createSwitch(name, label, initialValue, _id, _classname) {
  var html = '';
  var id = _id || ('switch_purpose_' + rnd);
  var checked = initialValue ? 'checked' : '';
  var classname = _classname || '';

  html += '<label class="switch ' + classname +'">';
  html += '<input ' + checked + ' type="checkbox" value="1" id="' + id + '" name="' + name + '">';
  html += '<span></span> <i class="label_shbnr_cs_' + rnd + '" id="' + id + '_label">' + label + '</i>';
  html += '</label>';

  return html;
}

function getPurposesList() {  
  var purposes = [];
  for (var id in gvl.vendors) {
    for (var i = 0; i < gvl.vendors[id].purposes.length; i++) {
      var v = +gvl.vendors[id].purposes[i];
      if (purposes.indexOf(v) == -1)
        purposes.push(v);
    }
  }
  //purposes.sort();

  var html = '';
  for (var i = 0; i < purposes.length; i++) {
    var v = purposes[i];
    html += '<div class="text_shbnr_cs_' + rnd + '">' + createSwitch(
      'purpose_switch_' + v, 
      gvl.purposes[v].name, 
      '', 
      v, 
      'purpose_switch purpose_switch_' + v + '_' + rnd
    ) + '</div>';
  }
  return html;
}

function getSpecialPurposesList() {  
  var specialPurposes = [];
  for (var id in gvl.vendors) {
    for (var i = 0; i < gvl.vendors[id].specialPurposes.length; i++) {
      var v = +gvl.vendors[id].specialPurposes[i];
      if (specialPurposes.indexOf(v) == -1)
        specialPurposes.push(v);
    }
  }
  //specialPurposes.sort();

  var html = '';
  for (var i = 0; i < specialPurposes.length; i++) {
    var v = specialPurposes[i];
    html += '<div class="text_shbnr_cs_' + rnd + '">* ' 
      + gvl.specialPurposes[v].name
      + '</div>';
  }
  return html;
}

function getFeaturesList() {  
  var features = [];
  for (var id in gvl.vendors) {
    for (var i = 0; i < gvl.vendors[id].features.length; i++) {
      var v = +gvl.vendors[id].features[i];
      if (features.indexOf(v) == -1)
        features.push(v);
    }
  }
  //features.sort();

  var html = '';
  for (var i = 0; i < features.length; i++) {
    var v = features[i];
    html += '<div class="text_shbnr_cs_' + rnd + '">* ' 
      + gvl.features[v].name
      + '</div>';
  }
  return html;
}

function getSpecialFeaturesList() {  
  var specialFeatures = [];
  for (var id in gvl.vendors) {
    for (var i = 0; i < gvl.vendors[id].specialFeatures.length; i++) {
      var v = +gvl.vendors[id].specialFeatures[i];
      if (specialFeatures.indexOf(v) == -1)
        specialFeatures.push(v);
    }
  }
  //specialFeatures.sort();

  var html = '';
  for (var i = 0; i < specialFeatures.length; i++) {
    var v = specialFeatures[i];
    html += '<div class="text_shbnr_cs_' + rnd + '">' + createSwitch(
      'specialFeature_switch_' + v, 
      gvl.specialFeatures[v].name, 
      '', 
      v, 
      'specialFeature_switch specialFeature_switch_' + v + '_' + rnd
    ) + '</div>';
  }
  return html;
}

/**
 *  Ritorna true se ci sono dei vendor che hanno delle legIntPurposes.
 *  Se non ce ne sono, vengono nascosti i testi che si riferiscono al
 *  legittimo interesse.
 */
function areThereLegintPurposes() {
  for (var id in gvl.vendors) {
    if (gvl.vendors[id].legIntPurposes.length > 0)
      return true;
  }
  return false;
}

function getExpandedPurposesList() {
  // gvl.vendors contiene i dettagli dei vendor selezionati.
  //console.log('>>> gvl.vendors', gvl.vendors);
  // ottengo la lista delle purposes e delle purposes legint
  var purposes = [];
  var purposes_legint = [];
  var partners_per_purpose = {};
  var partners_per_purpose_legint = {};
  for (var id in gvl.vendors) {
    for (var i = 0; i < gvl.vendors[id].purposes.length; i++) {
      var v = +gvl.vendors[id].purposes[i];
      if (purposes.indexOf(v) == -1)
        purposes.push(v);

      if (!partners_per_purpose[v])
        partners_per_purpose[v] = [];
      if (partners_per_purpose[v].indexOf(id) == -1)
        partners_per_purpose[v].push(id);
    }
    for (var i = 0; i < gvl.vendors[id].legIntPurposes.length; i++) {
      var v = +gvl.vendors[id].legIntPurposes[i];
      if (purposes_legint.indexOf(v) == -1)
        purposes_legint.push(v);

      if (!partners_per_purpose_legint[v])
        partners_per_purpose_legint[v] = [];
      if (partners_per_purpose_legint[v].indexOf(id) == -1)
        partners_per_purpose_legint[v].push(id);
    }
  }
  //console.log('>>> purposes', purposes);
  //console.log('>>> purposes_legint', purposes_legint);
  //purposes.sort();

  // merge dei purposes in un solo array e rimuove i duplicati
  var all_purposes = [...new Set([...purposes, ...purposes_legint])];
  //console.log('>>> all_purposes', all_purposes);
  //console.log('>>> partners_per_purpose', partners_per_purpose);

  var html = '<table class="table_shbnr_cs_' + rnd + '">';
  for (var i = 0; i < all_purposes.length; i++) {
    var v = all_purposes[i];
    var switch_leg = '';
    var switch_cons = '';

    if (purposes_legint.indexOf(v) > -1) {
      switch_leg = createSwitch(
        'purposeleg_switch_' + v, 
        config.labels.LEGITIMATE_INTEREST_LABEL, 
        true, 
        v, 
        'purposeleg_switch purposeleg_switch_' + v + '_' + rnd
      );
    }

    if (purposes.indexOf(v) > -1) {
      switch_cons = createSwitch(
        'purpose_switch_' + v, 
        config.labels.CONSENT_LABEL, 
        '', 
        v, 
        'purpose_switch purpose_switch_' + v + '_' + rnd
      );
    }

    var desc = gvl.purposes[v].description.replace(/(\r\n|\n|\r)/g,"<br />");
    var illustrations_text = gvl.purposes[v].illustrations.map(function(txt) { return '&#9656; ' + txt; }).join('<br>');
    if (illustrations_text != "") 
      desc = desc + '<br><br>' + config.labels.ILLUSTRATIONS_LABEL + '<br>' + illustrations_text;

    if (!partners_per_purpose[v]) partners_per_purpose[v] = [];
    if (!partners_per_purpose_legint[v]) partners_per_purpose_legint[v] = [];

    let vendors_names = '';
    if (partners_per_purpose[v].length > 0) {
      vendors_names += `<div>${config.labels.VENDORS_REQUESTING_CONSENT} ` + partners_per_purpose[v].map(function(vendor) {
        return '<b>' + gvl.vendors[vendor].name + '</b>';
      }).join(", ") + '</div>';
    }
    if (partners_per_purpose_legint[v].length > 0) {
      vendors_names += `<div>${config.labels.VENDORS_REQUESTING_LEGINT} ` + partners_per_purpose_legint[v].map(function(vendor) {
        return '<b>' + gvl.vendors[vendor].name + '</b>';
      }).join(", ") + '</div>';
    }

    html += `
      <tbody>
        <tr>
          <th data-id="${v}" class="expand_th_shbnr_cs_${rnd}">
            ${gvl.purposes[v].name}<br>
            <small>${config.labels.REQUESTED_BY} <b>${partners_per_purpose[v].length + partners_per_purpose_legint[v].length}</b> ${config.labels.VENDORS}</small>
          </th>
          <th width="200">
            ${switch_leg}
          </th>
          <th width="200">
            ${switch_cons}
          </th>
          <th class="expand_th_shbnr_cs_${rnd}" width="40" style="text-align:center;">
            ${EXPAND_ARROW}
          </th>
        </tr>
        <tr class="tr_detail_shbnr_cs_${rnd}">
          <td colspan="4">
            ${vendors_names}<br>
            ${desc}
          </td>
        </tr>
      </tbody>
    `;
  }
  html += '</table>';
  return html;
}

function getExpandedSpecialPurposesList() {
  var specialPurposes = [];
  var partners_per_purpose_legint = {};
  for (var id in gvl.vendors) {
    for (var i = 0; i < gvl.vendors[id].specialPurposes.length; i++) {
      var v = +gvl.vendors[id].specialPurposes[i];
      if (specialPurposes.indexOf(v) == -1)
        specialPurposes.push(v);

      if (!partners_per_purpose_legint[v])
        partners_per_purpose_legint[v] = [];
      if (partners_per_purpose_legint[v].indexOf(id) == -1)
        partners_per_purpose_legint[v].push(id);
    }
  }
  //specialPurposes.sort();
  
  var html = '';
  if (specialPurposes.length > 0) {
    var html = '<table class="table_shbnr_cs_' + rnd + '">';
    for (var i = 0; i < specialPurposes.length; i++) {
      var v = specialPurposes[i];
      var desc = gvl.specialPurposes[v].description.replace(/(\r\n|\n|\r)/g,"<br />");
      var illustrations_text = gvl.specialPurposes[v].illustrations.map(function(txt) { return '&#9656; ' + txt; }).join('<br>');
      if (illustrations_text != "") 
        desc = desc + '<br><br>' + config.labels.ILLUSTRATIONS_LABEL + '<br>' + illustrations_text;

      if (!partners_per_purpose_legint[v]) partners_per_purpose_legint[v] = [];
      
      let vendors_names = '';
      if (partners_per_purpose_legint[v].length > 0) {
        vendors_names += `<div>${config.labels.VENDORS_REQUESTING_LEGINT} ` + partners_per_purpose_legint[v].map(function(vendor) {
          return '<b>' + gvl.vendors[vendor].name + '</b>';
        }).join(", ") + '</div>';
      }

      html += `
        <tbody>
          <tr>
            <th class="expand_th_shbnr_cs_${rnd}">
              ${gvl.specialPurposes[v].name}<br>
              <small>${config.labels.USED_BY} <b>${partners_per_purpose_legint[v].length}</b> ${config.labels.VENDORS}</small>
            </th>
            <th class="expand_th_shbnr_cs_${rnd}" width="40" style="text-align:center;">
              ${EXPAND_ARROW}
            </th>
          </tr>
          <tr class="tr_detail_shbnr_cs_${rnd}">
            <td colspan="2">
              ${vendors_names}<br>
              ${desc}
            </td>
          </tr>
        </tbody>
      `;
    }
    html += '</table>';
  }
  return html;
}

function getExpandedFeaturesList() {
  var features = [];
  var partners_per_purpose = {};
  for (var id in gvl.vendors) {
    for (var i = 0; i < gvl.vendors[id].features.length; i++) {
      var v = +gvl.vendors[id].features[i];
      if (features.indexOf(v) == -1)
        features.push(v);

      if (!partners_per_purpose[v])
        partners_per_purpose[v] = [];
      if (partners_per_purpose[v].indexOf(id) == -1)
        partners_per_purpose[v].push(id);
    }
  }
  //features.sort();
  
  var html = '';
  if (features.length > 0) {
    var html = '<table class="table_shbnr_cs_' + rnd + '">';
    for (var i = 0; i < features.length; i++) {
      var v = features[i];
      var desc = gvl.features[v].description.replace(/(\r\n|\n|\r)/g,"<br />");
      var illustrations_text = gvl.features[v].illustrations.map(function(txt) { return '&#9656; ' + txt; }).join('<br>');
      if (illustrations_text != "") 
        desc = desc + '<br><br>' + config.labels.ILLUSTRATIONS_LABEL + '<br>' + illustrations_text;

      if (!partners_per_purpose[v]) partners_per_purpose[v] = [];

      let vendors_names = '';
      if (partners_per_purpose[v].length > 0) {
        vendors_names += `<div>${config.labels.VENDORS_USING_FEATURE} ` + partners_per_purpose[v].map(function(vendor) {
          return '<b>' + gvl.vendors[vendor].name + '</b>';
        }).join(", ") + '</div>';
      }

      html += `
        <tbody>
          <tr>
            <th class="expand_th_shbnr_cs_${rnd}">
              ${gvl.features[v].name}<br>
              <small>${config.labels.REQUESTED_BY} <b>${partners_per_purpose[v].length}</b> ${config.labels.VENDORS}</small>
            </th>
            <th class="expand_th_shbnr_cs_${rnd}" width="40" style="text-align:center;">
              ${EXPAND_ARROW}
            </th>
          </tr>
          <tr class="tr_detail_shbnr_cs_${rnd}">
            <td colspan="2">
              ${vendors_names}<br>
              ${desc}
            </td>
          </tr>
        </tbody>
      `;
    }
    html += '</table>';
  }
  return html;
}

function getExpandedDataCategoriesList() {
  var dataCategories = [];
  var partners_per_category = {};
  for (var id in gvl.vendors) {
    for (var i = 0; i < gvl.vendors[id].dataDeclaration.length; i++) {
      var v = +gvl.vendors[id].dataDeclaration[i];
      if (dataCategories.indexOf(v) == -1)
        dataCategories.push(v);

      if (!partners_per_category[v])
        partners_per_category[v] = [];
      if (partners_per_category[v].indexOf(id) == -1)
        partners_per_category[v].push(id);
    }
  }

  var html = '';
  if (dataCategories.length > 0) {
    var html = '<table class="table_shbnr_cs_' + rnd + '">';
    for (var i = 0; i < dataCategories.length; i++) {
      var v = dataCategories[i];
      var desc = gvl.dataCategories[v].description.replace(/(\r\n|\n|\r)/g,"<br />");

      if (!partners_per_category[v]) partners_per_category[v] = [];

      let vendors_names = '';
      if (partners_per_category[v].length > 0) {
        vendors_names += `<div>${config.labels.VENDORS_PROCESSING_CATEGORY} ` + partners_per_category[v].map(function(vendor) {
          return '<b>' + gvl.vendors[vendor].name + '</b>';
        }).join(", ") + '</div>';
      }

      html += `
        <tbody>
          <tr>
            <th class="expand_th_shbnr_cs_${rnd}">
              ${gvl.dataCategories[v].name}<br>
              <small>${config.labels.PROCESSED_BY} <b>${partners_per_category[v].length}</b> ${config.labels.VENDORS}</small>
            </th>
            <th class="expand_th_shbnr_cs_${rnd}" width="40" style="text-align:center;">
              ${EXPAND_ARROW}
            </th>
          </tr>
          <tr class="tr_detail_shbnr_cs_${rnd}">
            <td colspan="2">
              ${vendors_names}<br>
              ${desc}
            </td>
          </tr>
        </tbody>
      `;
    }
    html += '</table>';
  }
  return html;
}

function getExpandedSpecialFeaturesList() {
  var specialFeatures = [];
  var partners_per_purpose = {};
  for (var id in gvl.vendors) {
    for (var i = 0; i < gvl.vendors[id].specialFeatures.length; i++) {
      var v = +gvl.vendors[id].specialFeatures[i];
      if (specialFeatures.indexOf(v) == -1)
        specialFeatures.push(v);

      if (!partners_per_purpose[v])
        partners_per_purpose[v] = [];
      if (partners_per_purpose[v].indexOf(id) == -1)
        partners_per_purpose[v].push(id);
    }
  }
  //specialFeatures.sort();
  
  var html = '';
  if (specialFeatures.length > 0) {
    var html = '<table class="table_shbnr_cs_' + rnd + '">';
    for (var i = 0; i < specialFeatures.length; i++) {
      var v = specialFeatures[i];
      var sf_switch = createSwitch(
        'specialFeature_switch_' + v, 
        '', 
        '', 
        v, 
        'specialFeature_switch specialFeature_switch_' + v + '_' + rnd
      );
      var desc = gvl.specialFeatures[v].description.replace(/(\r\n|\n|\r)/g,"<br />");
      var illustrations_text = gvl.specialFeatures[v].illustrations.map(function(txt) { return '&#9656; ' + txt; }).join('<br>');
      if (illustrations_text != "") 
        desc = desc + '<br><br>' + config.labels.ILLUSTRATIONS_LABEL + '<br>' + illustrations_text;

      if (!partners_per_purpose[v]) partners_per_purpose[v] = [];
      
      let vendors_names = '';
      if (partners_per_purpose[v].length > 0) {
        vendors_names += `<div>${config.labels.VENDORS_REQUESTING_CONSENT_FEATURE} ` + partners_per_purpose[v].map(function(vendor) {
          return '<b>' + gvl.vendors[vendor].name + '</b>';
        }).join(", ") + '</div>';
      }

      html += `
        <tbody>
          <tr>
            <th class="expand_th_shbnr_cs_${rnd}">
              ${gvl.specialFeatures[v].name}<br>
              <small>${config.labels.REQUESTED_BY} <b>${partners_per_purpose[v].length}</b> ${config.labels.VENDORS}</small>
            </th>
            <th width="200">
              ${sf_switch}
            </th>
            <th class="expand_th_shbnr_cs_${rnd}" width="40" style="text-align:center;">
              ${EXPAND_ARROW}
            </th>
          </tr>
          <tr class="tr_detail_shbnr_cs_${rnd}">
            <td colspan="3">
              ${vendors_names}<br>
              ${desc}
            </td>
          </tr>
        </tbody>
      `;
    }
    html += '</table>';
  }
  
  return html;
}

function getVendorDetails(id) {
  function getRetention(vendor, vendor_arr_name, purpose_id) {
    const have_retention = [
      'purposes', 'legIntPurposes', 'specialPurposes'
    ];
    if (have_retention.indexOf(vendor_arr_name) == -1)
      return '';

    var retention = 0;
    if (vendor_arr_name == 'specialPurposes') {
      if (vendor.dataRetention.specialPurposes[purpose_id])
        retention = vendor.dataRetention.specialPurposes[purpose_id];
    } else {
      if (vendor.dataRetention.purposes[purpose_id])
        retention = vendor.dataRetention.purposes[purpose_id];
    }
    if (retention == 0)
      if (vendor.dataRetention.stdRetention)
        retention = vendor.dataRetention.stdRetention;

    return '<span class="evidence_shbnr_cs_' + rnd + '">' + config.labels.RETENTION_PERIOD + ' <b>' +  retention + '</b></span>';
  }

  function getlist(title, vendor_arr_name) {
    var html = '';
    
    var gvl_arr_name = vendor_arr_name;
    if (vendor_arr_name == 'legIntPurposes')
      gvl_arr_name = 'purposes';
    if (vendor_arr_name == 'dataDeclaration')
      gvl_arr_name = 'dataCategories';
    for (var i = 0; i < gvl.vendors[id][vendor_arr_name].length; i++) {
      var v = gvl.vendors[id][vendor_arr_name][i];
      if (i == 0)
        html += '<h2>' + title + '</h2>';
      var retention = getRetention(gvl.vendors[id], vendor_arr_name, v);
      html += '<div>' + gvl[gvl_arr_name][v].name + retention + '</div>';
    }
    return html;
  }
  
  var purposes_consent = getlist('Purposes (Consent)', 'purposes');
  var purposes_legint = getlist('Purposes (Legitimate Interest)', 'legIntPurposes');
  var special_purposes = getlist('Special Purposes', 'specialPurposes');
  var features = getlist('Features', 'features');
  var special_features = getlist('Special Features', 'specialFeatures');
  var data_categories = getlist('Data Categories', 'dataDeclaration');
  var privacy_policy = gvl.vendors[id].urls.map(function(url) {
    var rows = [];
    if (url.privacy)
      rows.push('<b>' + config.labels.POLICY_URL + '</b>: <a target="_blank" href="' + url.privacy + '">' + url.privacy + '</a>');
    if (url.legIntClaim)
      rows.push('<b>' + config.labels.LEGITIMATE_INTEREST_CLAIM + '</b>: <a target="_blank" href="' + url.legIntClaim + '">' + url.legIntClaim + '</a>');
    if (rows.length > 0)
      return '(' + url.langId + ')' + '<div class="policy_container_shbnr_cs_' + rnd + '">' + rows.join('<br>') + '</div>'
    else
      return ''
  }).join('<br>'); 
  //'<div class="policy_url_shbnr_cs_' + rnd + '"><b>Policy URL</b>: <a target="_blank" href="' + gvl.vendors[id]["policyUrl"] + '">' + gvl.vendors[id]["policyUrl"] + '</a>';
  
  // get the labels for the new planet49 infos
  var usesNonCookieAccess_label = config.labels.USES_NONCOOKIE_ACCESS || 'This vendor uses other storage methods (e.g. local storage) or accesses information on your device.';
  var cookieMaxAge_label = config.labels.MAX_COOKIE_AGE_DURATION || 'Max cookie age duration';
  var days_label = config.labels.DAYS || 'days';
  var moreDetails_label = config.labels.SHOW_DETAILS || 'Show details';
  
  if (config.labels.USES_NONCOOKIE_ACCESSS_LABEL)
    usesNonCookieAccess_label = config.labels.USES_NONCOOKIE_ACCESSS_LABEL || usesNonCookieAccess_label;
  if (config.labels.COOKIE_MAX_AGE_LABEL)
    cookieMaxAge_label = config.labels.COOKIE_MAX_AGE_LABEL || usesNonCookieAccess_label;
  if (config.labels.DAYS_LABEL)
    days_label = config.labels.DAYS_LABEL || days_label;
  if (config.labels.MORE_DETAILS_LABEL)
    moreDetails_label = config.labels.MORE_DETAILS_LABEL || moreDetails_label;
  
  var cookie_infos = '';
  if (gvl.vendors[id]["usesNonCookieAccess"]) {
    cookie_infos += '<div><h2>' + usesNonCookieAccess_label + '</h2></div>';
  }
  if (gvl.vendors[id]["cookieMaxAgeSeconds"]) {
    var value = Math.floor(((gvl.vendors[id]["cookieMaxAgeSeconds"] / 60) / 60) / 24);
    cookie_infos += '<div><b>' + cookieMaxAge_label + '</b>: ' + value + ' ' + days_label + '</div>';
  }
  // 2021 Amendment: disclose if the cookie may be refreshed
  if (gvl.vendors[id]["cookieRefresh"]) {
    var label_1 = config.labels.COOKIE_REFRESH_LABEL_1 || 'Duration may be refreshed and expire';
    var label_2 = config.labels.COOKIE_REFRESH_LABEL_2 || 'from your last interaction with the property';
    cookie_infos += '<div><b>' + label_1 + ' ' + value + ' ' + days_label + ' ' + label_2 + '.</b></div>';
  }
  
  if (gvl.vendors[id]["deviceStorageDisclosureUrl"]) {
    var info_container = '<div class="info-container_' + rnd + '"></div>';
    cookie_infos += '<div><a data-id="' + id + '" class="disclosure_url_' + rnd + '">' + moreDetails_label + '</a>' + info_container + '</div>';
  }
  
  return cookie_infos +
    purposes_consent +
    purposes_legint +
    special_purposes +
    features +
    special_features +
    data_categories +
    '<h2>Privacy Policy</h2><div>' + privacy_policy + '</div>'
}

function getPartnersList() {
  var html = '<table class="table_shbnr_cs_' + rnd + '">';
  
  var custom_vendor_html = '';
  for (var vendor in config.custom_vendors) {
    var vendor_desc = config.custom_vendors[vendor].desc;
    var vendor_id = config.custom_vendors[vendor].id;
    var switch_cons = createSwitch(
      'customVendor_switch_' + vendor_id, 
      config.labels.CONSENT_LABEL, 
      '', 
      vendor_id, 
      'customVendor_switch customVendor_switch_' + vendor_id + '_' + rnd
    );
    custom_vendor_html += `
      <tbody>
        <tr>
          <th class="expand_th_shbnr_cs_${rnd}">
            ${vendor}
          </th>
          <th width="200">
            &nbsp;
          </th>
          <th width="200">
            ${switch_cons}
          </th>
          <th class="expand_th_shbnr_cs_${rnd}" width="40" style="text-align:center;">
            ${EXPAND_ARROW}
          </th>
        </tr>
        <tr class="tr_detail_shbnr_cs_${rnd}">
          <td colspan="4">
            ${vendor_desc}
          </td>
        </tr>
      </tbody>
    `;
  }
  if (custom_vendor_html != '') {
    html += vendor_header(config.labels.PARTNERS_NOT_IAB) + custom_vendor_html + vendor_header(config.labels.PARTNERS_IAB);
  }
  
  for (var id in gvl.vendors) {
    var switch_leg = createSwitch(
      'vendorleg_switch_' + id, 
      config.labels.LEGITIMATE_INTEREST_LABEL, 
      true,
      id, 
      'vendorleg_switch vendorleg_switch_' + id + '_' + rnd
    );
    var switch_cons = createSwitch(
      'vendor_switch_' + id, 
      config.labels.CONSENT_LABEL, 
      '', 
      id, 
      'vendor_switch vendor_switch_' + id + '_' + rnd
    );
    var vendor_details = getVendorDetails(id);
    
    // 3/6/21 disabilito il pulsante per il legittimo interesse nella lista dei partner
    //  (come da mail avvocato del 1/6) (per riabilitarlo, eliminare il div display:none)
    switch_leg = '<div style="display:none;">' + switch_leg + '</div>';
    
    html += `
      <tbody>
        <tr>
          <th class="expand_th_shbnr_cs_${rnd}">
            ${gvl.vendors[id].name}
          </th>
          <th width="200">
            ${switch_leg}
          </th>
          <th width="200">
            ${switch_cons}
          </th>
          <th class="expand_th_shbnr_cs_${rnd}" width="40" style="text-align:center;">
            ${EXPAND_ARROW}
          </th>
        </tr>
        <tr class="tr_detail_shbnr_cs_${rnd}">
          <td colspan="4">
            ${vendor_details}
          </td>
        </tr>
      </tbody>
    `;
  }
  html += '</table>';
  return html;
}

function expandArrow(th) {
  var tbody = th.parentElement.parentElement;
  
  var cname = 'expanded_shbnr_cs_' + rnd;
  if (tbody.classList.contains(cname)) {
    tbody.classList.remove(cname);
  } else {
    tbody.classList.add(cname);
  }
}

function hideUI() {
  document.querySelector('.div_shbnr_cs_' + rnd).style.display = 'none';
}

function showUI() {
  document.querySelector('.div_shbnr_cs_' + rnd).style.display = 'block';
}

function showMiniLayer() {
  document.querySelector('.minilayer_shbnr_cs_' + rnd).style.display = 'block';
}

function hideMiniLayer() {
  document.querySelector('.minilayer_shbnr_cs_' + rnd).style.display = 'none';
}

function manageSettingsHandler() {
  showLayer2();
}

function partnersLinkHandler() {
  showLayer3();
}

function purposesLinkHandler() {
  showLayer2();
}

function expandArrowHandler(evt) {
  expandArrow(evt.currentTarget);
}

function savebutton_handler() {
  onSave();
  hideUI();
  showMiniLayer();
}

function minilayerClickHandler() {
  showUI();
  hideMiniLayer();
}

function mouseenter_close_handler(evt) {
  var el = evt.currentTarget.querySelector('.truelabel_cs_' + rnd);
  if (el) {
    el.style.display = 'inline-block';
    evt.currentTarget.style.width = (el.offsetWidth + 45) + 'px';
    
    var classes = evt.currentTarget.className.split(' ');
    if (classes.indexOf('hovered') == -1) {
      classes.push('hovered');
      evt.currentTarget.className = classes.join(' ');
    }
  }
}

function mouseleave_close_handler(evt) {
  var el = evt.currentTarget.querySelector('.truelabel_cs_' + rnd);
  if (el) {
    el.style.display = 'none';
    evt.currentTarget.style.width = '24px';
    
    var classes = evt.currentTarget.className.split(' ');
    var idx = classes.indexOf('hovered');
    if (idx > -1) {
      classes.splice(idx, 1);
      evt.currentTarget.className = classes.join(' ');
    }
  }
}

function getDisclosureUrl(evt) {
  var disclosureUrlErrorLoading_label = 'An error occurred while loading the requested vendor informations. You may want to check the informations in JSON format at';
  var identifier_label = 'Identifier';
  var type_label = 'Memory type';
  var maxAgeSeconds_label = 'Max age duration';
  var domain_label = 'Domain';
  var purposes_label = 'Purposes';
  var days_label = 'days';
 
  var id = evt.target.getAttribute('data-id');
  var url = gvl.vendors[id]["deviceStorageDisclosureUrl"];
  
  function translateDisclosureUrlResponseToHtml(resp) {
    var html = '';
    try {
      var json = JSON.parse(resp);
    } catch (err) {
      html = disclosureUrlErrorLoading_label + ' <a target="_blank" href="' + url + '">' + url + '</a>';;
    }
    for (var i = 0; i < json.disclosures.length; i++) {
      var disclosure_content = '';
      if (json.disclosures[i].identifier)
        disclosure_content += '<div><b>' + identifier_label + '</b>: ' + json.disclosures[i].identifier + '</div>';
      if (json.disclosures[i].type)
        disclosure_content += '<div><b>' + type_label + '</b>: ' + json.disclosures[i].type + '</div>';
      if (json.disclosures[i].maxAgeSeconds) {
        var value = Math.floor(((json.disclosures[i].maxAgeSeconds / 60) / 60) / 24);
        disclosure_content += '<div><b>' + maxAgeSeconds_label + '</b>: ' + value + ' ' + days_label + '</div>';
      }
      if (json.disclosures[i].domain)
        disclosure_content += '<div><b>' + domain_label + '</b>: ' + json.disclosures[i].domain + '</div>';
      if (json.disclosures[i].purposes) {
        var purposes_names = [];
        for (var p = 0; p < json.disclosures[i].purposes.length; p++) {
          var purpose_id = json.disclosures[i].purposes[p];
          purposes_names.push(gvl.purposes[purpose_id].name);
        }
        disclosure_content += '<div><b>' + purposes_label + '</b>: ' + purposes_names.join(', ') + '</div>';
      }
      html += '<div class="disclosure_' + rnd + '">' + disclosure_content + '</div>';
    }
    
    return html;
  }
 
  Utils.get(url, function(response) {
    var html = '';
    if (response == '') {
      html = disclosureUrlErrorLoading_label + ' <a target="_blank" href="' + url + '">' + url + '</a>';
    } else {
      html = translateDisclosureUrlResponseToHtml(response);
    }
    evt.target.parentElement.querySelector('.info-container_' + rnd).innerHTML = html;
  });
}

function setSwitches(layerNum, value) {
  var items = document.querySelectorAll('.layer' + layerNum + '_shbnr_cs_' + rnd + ' .switch > input[type="checkbox"]');
  for (var i = 0; i < items.length; i++) {
    // sempre in relazione alla mail dell'avvocato del 1/6/21, visto che i legitimate interest non sono visualizzati,
    // evito di processarli. manterranno così sempre il valore true di default.
    if (items[i].parentElement.className.indexOf('vendorleg_switch') == -1)
      items[i].checked = value;
  }
}

function setSwitchesFromModel(selector, modelVector) {
  document.querySelectorAll(selector).forEach(function(item) {
    if (modelVector.has(+item.id)) {
      item.checked = true;
    } else {
      item.checked = false;
    }
  });
}

function setCustomSwitches(custom_arr) {
  document.querySelectorAll(`.layer3_shbnr_cs_${rnd} .customVendor_switch input`).forEach(function(item) {
    if (custom_arr.indexOf(item.id) > -1) {
      item.checked = true;
    } else {
      item.checked = false;
    }
  });
}

function acceptAll_1_buttonHandler() {
  setSwitches(1, true);
  setSwitches(2, true);
  setSwitches(3, true);

  if (config.show_save_exit_first_layer === false) {
    savebutton_handler();
  }
}

function acceptAll_2_buttonHandler() {
  setSwitches(1, true);
  setSwitches(2, true);
  setSwitches(3, true);
}

function acceptAll_3_buttonHandler() {
  setSwitches(1, true);
  setSwitches(2, true);
  setSwitches(3, true);
}

function rejectAll_2_buttonHandler() {
  setSwitches(1, false);
  setSwitches(2, false);
  setSwitches(3, false);
}

function rejectAll_3_buttonHandler() {
  setSwitches(1, false);
  setSwitches(2, false);
  setSwitches(3, false);
}

function linkedSwitchesHandler(evt) {
  var el = evt.currentTarget;
  document.querySelectorAll('input[name="' + el.name + '"]').forEach(function(item) {
    item.checked = el.checked;
  });
}

function showLayer2() {
  document.querySelector('.layer1_shbnr_cs_' + rnd).style.display = 'none';
  document.querySelector('.layer3_shbnr_cs_' + rnd).style.display = 'none';
  document.querySelector('.layer2_shbnr_cs_' + rnd).style.display = 'block';
}

function showLayer3() {
  document.querySelector('.layer1_shbnr_cs_' + rnd).style.display = 'none';
  document.querySelector('.layer2_shbnr_cs_' + rnd).style.display = 'none';
  document.querySelector('.layer3_shbnr_cs_' + rnd).style.display = 'block';
}

function createLayer(opts) {
  div = document.createElement('DIV');
  div.className = 'div_shbnr_cs_' + rnd;
  div.style.display = opts && opts.hidden && opts.hidden == true ? 'none' : 'block';
  
  var _ = config.labels;
  var purposes_html = getPurposesList();
  var specialpurposes_html = getSpecialPurposesList();
  var features_html = getFeaturesList();
  var specialfeatures_html = getSpecialFeaturesList();
  var expanded_purposes_html = getExpandedPurposesList();
  var expanded_special_purposes_html = getExpandedSpecialPurposesList();
  var expanded_features_html = getExpandedFeaturesList();
  var expanded_special_features_html = getExpandedSpecialFeaturesList();
  var expanded_data_categories_html = getExpandedDataCategoriesList();
  var partners_list_html = getPartnersList();
  var PARTNERS_LINK = '<a class="partners_link_shbnr_cs_' + rnd + '">' + _.GO_TO_VENDORS_LIST + '</a>';
  var PURPOSES_LINK = '<a class="purposes_link_shbnr_cs_' + rnd + '">' + _.GO_TO_PURPOSES_LIST + '</a>';
  var compiled_main_text = _.MAIN_TEXT.replace('{{VENDOR_LINK}}', PARTNERS_LINK);

  // TCF2.2: aggiungo il numero di vendor presenti nel sito
  const vendors_number = config.vendors_allowed.length;
  const custom_vendors_number = Object.keys(config.custom_vendors).length;
  compiled_main_text = compiled_main_text.replace('{{VENDORS_NUMBER}}', vendors_number)
    .replace('{{CUSTOM_VENDORS_NUMBER}}', custom_vendors_number)
    .replace('{{TOTAL_VENDORS_NUMBER}}', vendors_number + custom_vendors_number);

  var show_save_exit_first_layer = config.show_save_exit_first_layer === undefined ? true : config.show_save_exit_first_layer;
  var close_button_hover_label = config.close_button_hover_label || '';

  var purposes_text = _.PURPOSES_TEXT_2;
  if (areThereLegintPurposes())
    purposes_text += _.PURPOSES_TEXT_LEGINT;
  purposes_text += _.PURPOSES_TEXT_END;
  var COMPILED_PURPOSES_TEXT_2 = purposes_text.replace('{{VENDOR_LINK}}', PARTNERS_LINK);
  
  var vendors_text = _.VENDORS_TEXT_2;
  // disattivo anche questo riferimento al legittimo interesse sul partner, sempre
  // in relazione alla mail avvocato del 1/6/2021 (per riabilitarlo, commentare le seguenti due righe)
  //if (areThereLegintPurposes())
    //vendors_text += _.VENDORS_TEXT_LEGINT;
  vendors_text += _.VENDORS_TEXT_END;
  
  var specialfeatures_section = specialfeatures_html == '' ? '' : `
    <div class="mt20_shbnr_cs_${rnd} text_shbnr_cs_${rnd}">${_.SPECIAL_FEATURES_TEXT}</div>
    <div class="specialfeatureslist_shbnr_cs_${rnd}">${specialfeatures_html}</div>
  `;
  var features_section = features_html == '' ? '' : `
    <div class="mt20_shbnr_cs_${rnd} text_shbnr_cs_${rnd}">${_.FEATURES_TEXT}</div>
    <div class="featureslist_shbnr_cs_${rnd}">${features_html}</div>
  `;  

  var expanded_special_features_section = expanded_special_features_html == '' ? '' : `
    <div class="sectiontitle_shbnr_cs_${rnd}">${_.SPECIAL_FEATURES}</div>
    <div class="sectionlist_shbnr_cs_${rnd}">${_.SPECIAL_FEATURES_TEXT}<br>${expanded_special_features_html}</div>
  `;

  var expanded_special_purposes_section = expanded_special_purposes_html == '' ? '' : `
    <div class="sectiontitle_shbnr_cs_${rnd}">${_.SPECIAL_PURPOSES}</div>
    <div class="sectionlist_shbnr_cs_${rnd}">${_.SPECIAL_PURPOSES_TEXT}<br>${expanded_special_purposes_html}</div>
  `;

  var expanded_features_section = expanded_features_html == '' ? '' : `
    <div class="sectiontitle_shbnr_cs_${rnd}">${_.FEATURES}</div>
    <div class="sectionlist_shbnr_cs_${rnd}">${_.FEATURES_TEXT}<br>${expanded_features_html}</div>
  `;
  
  var expanded_data_categories_section = expanded_data_categories_html == '' ? '' : `
    <div class="sectiontitle_shbnr_cs_${rnd}">${_.DATA_CATEGORIES}</div>
    <div class="sectionlist_shbnr_cs_${rnd}">${_.DATA_CATEGORIES_TEXT}<br>${expanded_data_categories_html}</div>
  `;

  var save_exit_button = show_save_exit_first_layer ? `<button class="savebutton_shbnr_cs_${rnd} button_shbnr_cs_${rnd}">${_.SAVE_AND_EXIT}</button>` : '';
  var html = `
    <div class="main_innerdiv_shbnr_cs_${rnd}">
      <div class="containerdiv_shbnr_cs_${rnd}">
      
        <!-- layer 1 -->
        <div class="layer1_shbnr_cs_${rnd}">
          <div class="titlearea_shbnr_cs_${rnd}">
            <div class="titlearea_right_shbnr_cs_${rnd}">
              ${PARTNERS_LINK}
              <span class="material-icons savebutton_shbnr_cs_${rnd}"><span class="truelabel_cs_${rnd}">${close_button_hover_label}</span>close</span>
            </div>
            <div class="maintitle_shbnr_cs_${rnd}">${_.MAIN_TITLE}</div>
          </div>
          <div class="bodyarea_shbnr_cs_${rnd}">
            ${INNERDIV_OPEN}
              <div class="text_shbnr_cs_${rnd}">${compiled_main_text}</div>
              <div class="purposeslist_shbnr_cs_${rnd}">${purposes_html}</div>
              ${specialfeatures_section}
              ${features_section}
            </div>
          </div>
          <div class="buttonarea_shbnr_cs_${rnd}">
            <button class="manage_settings_button_shbnr_cs_${rnd} button_shbnr_cs_${rnd}">${_.MANAGE_SETTINGS}</button>
            <button class="accept_all_layer1_button_shbnr_cs_${rnd} button_shbnr_cs_${rnd}">${_.ACCEPT_ALL}</button>
            ${save_exit_button}
          </div>
        </div>
        
        <!-- layer 2 -->
        <div class="layer2_shbnr_cs_${rnd}">
          <div class="titlearea_shbnr_cs_${rnd}">
            <div class="titlearea_right_shbnr_cs_${rnd}">
              ${PARTNERS_LINK}
              <span class="material-icons savebutton_shbnr_cs_${rnd}">close</span>
              <button class="accept_all_layer2_button_shbnr_cs_${rnd} button_shbnr_cs_${rnd}">${_.ACCEPT_ALL}</button>
              <button class="reject_all_layer2_button_shbnr_cs_${rnd} button_shbnr_cs_${rnd}">${_.REJECT_ALL}</button>
              <button class="savebutton_shbnr_cs_${rnd} button_shbnr_cs_${rnd}">${_.SAVE_AND_EXIT}</button>
            </div>
            <div class="maintitle_shbnr_cs_${rnd}">${_.MANAGE_SETTINGS}</div>
          </div>
          <div class="bodyarea_shbnr_cs_${rnd}">
            ${INNERDIV_OPEN}
              <div class="sectiontitle_shbnr_cs_${rnd}">${_.PURPOSES}</div>
              <div class="sectionlist_shbnr_cs_${rnd}">${COMPILED_PURPOSES_TEXT_2}<br>${expanded_purposes_html}</div>
              ${expanded_special_features_section}
              ${expanded_special_purposes_section}
              ${expanded_features_section}
              ${expanded_data_categories_section}
            </div>
          </div>
          <div class="buttonarea_shbnr_cs_${rnd}">
            <button class="accept_all_layer2_button_shbnr_cs_${rnd} button_shbnr_cs_${rnd}">${_.ACCEPT_ALL}</button>
            <button class="reject_all_layer2_button_shbnr_cs_${rnd} button_shbnr_cs_${rnd}">${_.REJECT_ALL}</button>
            <button class="savebutton_shbnr_cs_${rnd} button_shbnr_cs_${rnd}">${_.SAVE_AND_EXIT}</button>
          </div>
        </div>
        
        <!-- layer 3 (partners) -->
        <div class="layer3_shbnr_cs_${rnd}">
          <div class="titlearea_shbnr_cs_${rnd}">
            <div class="titlearea_right_shbnr_cs_${rnd}">
              ${PURPOSES_LINK}
              <span class="material-icons savebutton_shbnr_cs_${rnd}">close</span>
              <button class="accept_all_layer3_button_shbnr_cs_${rnd} button_shbnr_cs_${rnd}">${_.ACCEPT_ALL}</button>
              <button class="reject_all_layer3_button_shbnr_cs_${rnd} button_shbnr_cs_${rnd}">${_.REJECT_ALL}</button>
              <button class="savebutton_shbnr_cs_${rnd} button_shbnr_cs_${rnd}">${_.SAVE_AND_EXIT}</button>
            </div>
            <div class="maintitle_shbnr_cs_${rnd}">${_.MANAGE_SETTINGS}</div>
          </div>
          <div class="bodyarea_shbnr_cs_${rnd}">
            ${INNERDIV_OPEN}
              <div class="sectiontitle_shbnr_cs_${rnd}">${_.PARTNERS}</div>
              <div class="sectionlist_shbnr_cs_${rnd}">${vendors_text}<br>${partners_list_html}</div>
            </div>
          </div>
          <div class="buttonarea_shbnr_cs_${rnd}">
            <button class="accept_all_layer3_button_shbnr_cs_${rnd} button_shbnr_cs_${rnd}">${_.ACCEPT_ALL}</button>
            <button class="reject_all_layer3_button_shbnr_cs_${rnd} button_shbnr_cs_${rnd}">${_.REJECT_ALL}</button>
            <button class="savebutton_shbnr_cs_${rnd} button_shbnr_cs_${rnd}">${_.SAVE_AND_EXIT}</button>
          </div>
        </div>
        
      </div>
    </div>
  `
  div.innerHTML = html;
  
  minidiv = document.createElement('DIV');
  minidiv.className = 'minilayer_shbnr_cs_' + rnd;
  minidiv.innerHTML = `
    <div>
      ${_.MINILAYER_LABEL}
    </div>
  `;
  
  var css = `
    .div_shbnr_cs_${rnd} {position:fixed;top:0;left:0;bottom:0;right:0;background-color:rgba(255,255,255,0.6);z-index: 99999998 !important;font-size:11pt;}
    .main_innerdiv_shbnr_cs_${rnd} {position:fixed;top:40px;left:160px;right:160px;bottom:80px;}
    .titlearea_shbnr_cs_${rnd} {position:absolute;top:0;height:80px;width:100%;}
    .bodyarea_shbnr_cs_${rnd} {position:absolute;top:80px;bottom:100px;left:20px;right:20px;overflow-y:scroll;border:1px solid #8bc;}
    .buttonarea_shbnr_cs_${rnd} {position:absolute;height:80px;bottom:0;text-align:center;width:100%;}
    
    .titlearea_right_shbnr_cs_${rnd} {float:right;padding:20px 20px 0 0;}
    
    .innerdiv_shbnr_cs_${rnd} {padding:10px 20px;}
    .containerdiv_shbnr_cs_${rnd} {background-color:${_.MAIN_COLOR};color:#fff;${FONT_FAMILY_RULE};height:100%;}
    .maintitle_shbnr_cs_${rnd} {font-size:18pt;padding:20px;}
    .purposeslist_shbnr_cs_${rnd} {margin:10px 0;padding:10px 0;}
    .text_shbnr_cs_${rnd} {margin:10px 0;line-height:1.4em;}
    
    .button_shbnr_cs_${rnd} {font-weight:normal;margin:0 15px;background-color:rgba(0,0,0,0.3);color:white;border:2px solid white;border-radius:4px;padding:15px 5px;font-size:10pt;width:300px;text-transform:uppercase;cursor:pointer;}
    .button_shbnr_cs_${rnd}:hover {background-color:rgba(255,255,255,0.4);}
    .titlearea_right_shbnr_cs_${rnd} .button_shbnr_cs_${rnd} {padding:10px 5px;width:200px;font-size:9pt;margin:0 5px;}
    
    .switch *{cursor:pointer}.switch input{opacity:0;position:absolute;z-index:-1}.switch span{position:relative;display:inline-block;width:40px;height:20px;background-color:#fff;border:1px solid #ddd;border-radius:100px;transition:all .5s;box-shadow:inset 1px 2px 3px rgba(0,0,0,.1);vertical-align:middle}.switch input:checked+span{background-color:${SWITCH_CHECKED_COLOR};border-color:${SWITCH_CHECKED_BORDER_COLOR};transition:all .5s}.switch input:checked+span:after{left:50%;transition:all .2s}.switch span:after{content:"";position:absolute;background-color:#fff;top:0;left:0;height:18px;width:18px;border:1px solid #ddd;border-radius:400px;box-shadow:1px 1px 3px rgba(0,0,0,.1);-webkit-transition:all .2s}
    .layer2_shbnr_cs_${rnd}, .layer2_shbnr_cs_${rnd} .buttonarea_shbnr_cs_${rnd} {display:none;}
    .layer3_shbnr_cs_${rnd}, .layer3_shbnr_cs_${rnd} .buttonarea_shbnr_cs_${rnd} {display:none;}
    .sectiontitle_shbnr_cs_${rnd} {font-weight:bold;text-transform:uppercase;font-size:10pt;margin-bottom:10px;}
    .sectionlist_shbnr_cs_${rnd} {margin-bottom:40px;}
    .sectionlist_shbnr_cs_${rnd} table {margin-top:10px;}
    .table_shbnr_cs_${rnd} {border-collapse:collapse;width:100%;border:2px solid white;}
    .table_shbnr_cs_${rnd} th {${FONT_FAMILY_RULE};border-bottom:1px solid white;text-align:left;padding:10px;background-color:rgba(255,255,255,0.2);font-weight:normal;font-size:10pt;}
    .table_shbnr_cs_${rnd} thead th {background-color:#F7F8F9;padding:20px 0;font-weight:normal;font-size:10pt;color:#585F65;}
    .table_shbnr_cs_${rnd} td {${FONT_FAMILY_RULE};padding:10px 20px;}
    .table_shbnr_cs_${rnd} .tr_detail_shbnr_cs_${rnd} {display:none;}
    .table_shbnr_cs_${rnd} .tr_detail_shbnr_cs_${rnd} td {font-size:9pt;line-height:1.4em;border:1px solid white;}
    .table_shbnr_cs_${rnd} .tr_detail_shbnr_cs_${rnd} td h2 {font-size:1.2em;margin:20px 0 5px 0;color:#fff;${FONT_FAMILY_RULE};}
    .table_shbnr_cs_${rnd} tbody.expanded_shbnr_cs_${rnd} .tr_detail_shbnr_cs_${rnd} {display:table-row;}
    .table_shbnr_cs_${rnd} tbody.expanded_shbnr_cs_${rnd} .expand_arrow_shbnr_cs_${rnd} {transform:rotate(135deg);}
    
    .expand_th_shbnr_cs_${rnd} {cursor:pointer;}
    .expand_arrow_shbnr_cs_${rnd} {cursor:pointer;display:inline-block;width:15px;height:15px;border-right:1px solid white;border-bottom:1px solid white;transform:rotate(45deg);}
    
    .partners_link_shbnr_cs_${rnd},
    .purposes_link_shbnr_cs_${rnd} {text-decoration:underline;cursor:pointer;display:inline-block;margin-right:20px;}
    
    .policy_url_shbnr_cs_${rnd} {margin-top:20px;}
    
    .div_shbnr_cs_${rnd} a{color:white !important;text-decoration:underline !important;}
    .div_shbnr_cs_${rnd} a:link{color:white !important;text-decoration:underline !important;}
    .div_shbnr_cs_${rnd} a:visited{color:white !important;}
    .div_shbnr_cs_${rnd} a:hover{color:white !important;}
    
    .evidence_shbnr_cs_${rnd} {display:inline-block;background-color:#ddd;border-radius:2px;padding:0px 2px;margin-left:5px;margin-bottom:1px;}

    .minilayer_shbnr_cs_${rnd} {display:none;z-index:1000;cursor:pointer;padding:10px 20px;font-size:10pt;${FONT_FAMILY_RULE};background-color:${_.MAIN_COLOR};color:#fff;position:fixed;right:30px;}
    .minilayer_shbnr_cs_${rnd} {
      top:auto;left:auto;bottom:auto;right:auto;
      ${_.MINILAYER_VERT_POS}:${_.MINILAYER_VERT_OFFSET_PX}px;
      ${_.MINILAYER_HORZ_POS}:${_.MINILAYER_HORZ_OFFSET_PX}px;
    }
    
    .mt20_shbnr_cs_${rnd} {margin-top:30px;}
    .label_shbnr_cs_${rnd} {${FONT_FAMILY_RULE};}
    .disclosure_${rnd} {margin-top:10px;}
    
    .savebutton_shbnr_cs_${rnd} {cursor:pointer;}

    @media screen and (max-width: 680px) {
      .main_innerdiv_shbnr_cs_${rnd} {top:10px;left:10px;right:10px;bottom:10px;}
      .bodyarea_shbnr_cs_${rnd} {bottom:160px;left:10px;right:10px;}
      .buttonarea_shbnr_cs_${rnd} {height:auto;bottom:10px;}
      .button_shbnr_cs_${rnd} {margin:2px 15px;padding:12px 5px;}
      .innerdiv_shbnr_cs_${rnd} {padding:10px;}
      .titlearea_right_shbnr_cs_${rnd} button{display:none;}
      
      .layer2_shbnr_cs_${rnd} .buttonarea_shbnr_cs_${rnd} {display:block;}
      .layer3_shbnr_cs_${rnd} .buttonarea_shbnr_cs_${rnd} {display:block;}
    }
  `;

  if (close_button_hover_label != '') {
    css += `
      .savebutton_shbnr_cs_${rnd} {
        position:relative;
        width:24px;
        -webkit-transition: width 0.5s ease-out;
        -moz-transition: width 0.5s ease-out;
        -o-transition: width 0.5s ease-out;
        transition: width 0.5s ease-out;
      }
      .truelabel_cs_${rnd} {
        display:none;
        font-family: 'Open Sans';
        font-size: 12px;
        position:absolute;
        top:6px;right:20px;
      }
    `;
  }
  var style = document.createElement('STYLE');
  style.innerHTML = css;
  
  document.body.appendChild(style);
  document.body.appendChild(div);
  document.body.appendChild(minidiv);
  
  document.querySelector('.manage_settings_button_shbnr_cs_' + rnd).addEventListener('click', manageSettingsHandler);
  document.querySelectorAll('.expand_th_shbnr_cs_' + rnd).forEach(function(item) {
    item.addEventListener('click', expandArrowHandler);
  });
  
  document.querySelectorAll('.partners_link_shbnr_cs_' + rnd).forEach(function(item) {
    item.addEventListener('click', partnersLinkHandler);
  });
  document.querySelectorAll('.purposes_link_shbnr_cs_' + rnd).forEach(function(item) {
    item.addEventListener('click', purposesLinkHandler);
  });
  
  // link switches between layer 1 and layer 2
  document.querySelectorAll(`.layer1_shbnr_cs_${rnd} .purpose_switch input, .layer2_shbnr_cs_${rnd} .purpose_switch input`).forEach(function(item) {
    item.addEventListener('change', linkedSwitchesHandler);
  });
  document.querySelectorAll(`.layer1_shbnr_cs_${rnd} .specialFeature_switch input, .layer2_shbnr_cs_${rnd} .specialFeature_switch input`).forEach(function(item) {
    item.addEventListener('change', linkedSwitchesHandler);
  });
  
  document.querySelector('.accept_all_layer1_button_shbnr_cs_' + rnd).addEventListener('click', acceptAll_1_buttonHandler);
  document.querySelectorAll('.accept_all_layer2_button_shbnr_cs_' + rnd).forEach(function(item) {
    item.addEventListener('click', acceptAll_2_buttonHandler);
  });
  document.querySelectorAll('.accept_all_layer3_button_shbnr_cs_' + rnd).forEach(function(item) {
    item.addEventListener('click', acceptAll_3_buttonHandler);
  });
  document.querySelectorAll('.reject_all_layer2_button_shbnr_cs_' + rnd).forEach(function(item) {
    item.addEventListener('click', rejectAll_2_buttonHandler);
  });
  document.querySelectorAll('.reject_all_layer3_button_shbnr_cs_' + rnd).forEach(function(item) {
    item.addEventListener('click', rejectAll_3_buttonHandler);
  });
  document.querySelectorAll('.savebutton_shbnr_cs_' + rnd).forEach(function(item) {
    item.addEventListener('click', savebutton_handler);
  });
  
  document.querySelector('.minilayer_shbnr_cs_' + rnd).addEventListener('click', minilayerClickHandler);
  
  document.querySelectorAll('.disclosure_url_' + rnd).forEach(function(item) {
    item.addEventListener('click', getDisclosureUrl);
  });

  if (close_button_hover_label != '') {
    document.querySelectorAll('.titlearea_right_shbnr_cs_' + rnd + ' ' + '.savebutton_shbnr_cs_' + rnd).forEach(function(item) {
      item.addEventListener('mouseenter', mouseenter_close_handler);
      item.addEventListener('mouseleave', mouseleave_close_handler);
    });
  }
}

function loadCustomResources(config) {
  // carica un css esterno opzionale
  if (config.link_css) {
    if (!document.getElementById('shbnr_custom_style')) {
      var el = document.createElement('link');
      el.id = 'shbnr_custom_style';
      el.href = config.link_css;
      el.rel = 'stylesheet';
      document.body.appendChild(el);
    }
  }
  
  // carica eventuali google fonts
  if (config.gfont) {
    if (!document.getElementById('shbnr_gfont')) {
      var el = document.createElement('link');
      el.id = 'shbnr_gfont';
      el.href = 'https://fonts.googleapis.com/css2?family=' + config.gfont + '&display=swap';
      el.rel = 'stylesheet';
      document.body.appendChild(el);    
    }
  }
}

Ui.showBanner = function(_config, _gvl, _onSave, model, custom) {
  config = _config;
  
  // se è attivo il css custom, disattivo il random
  if (config.link_css)
    rnd = 's';
  
  gvl = _gvl;
  onSave = _onSave;
  
  if (model) {
    createLayer({'hidden': true});
    loadCustomResources(config);
    
    // set the flags from model
    setSwitchesFromModel(`.layer1_shbnr_cs_${rnd} .purpose_switch input`, model.purposeConsents);
    setSwitchesFromModel(`.layer2_shbnr_cs_${rnd} .purpose_switch input`, model.purposeConsents);
    setSwitchesFromModel(`.layer2_shbnr_cs_${rnd} .purposeleg_switch input`, model.purposeLegitimateInterests);
    setSwitchesFromModel(`.layer3_shbnr_cs_${rnd} .vendor_switch input`, model.vendorConsents);
    setSwitchesFromModel(`.layer3_shbnr_cs_${rnd} .vendorleg_switch input`, model.vendorLegitimateInterests);
    setSwitchesFromModel(`.layer1_shbnr_cs_${rnd} .specialFeature_switch input`, model.specialFeatureOptins);
    setSwitchesFromModel(`.layer2_shbnr_cs_${rnd} .specialFeature_switch input`, model.specialFeatureOptins);
    if (custom) 
      setCustomSwitches(custom);
    
    showMiniLayer();
    return;
  }
  createLayer();
  loadCustomResources(config);
};

Ui.getPurposeConsents = function() {
  var purposes = [];
  
  var selector = `.layer1_shbnr_cs_${rnd} .purpose_switch input`;
  var items = document.querySelectorAll(selector);
  items.forEach(function(el) {
    if (el.checked)
      purposes.push(+el.id);
  });
  
  return purposes;
};

Ui.getSpecialFeatureOptins = function() {
  var specialFeatures = [];
  
  var selector = `.layer1_shbnr_cs_${rnd} .specialFeature_switch input`;
  var items = document.querySelectorAll(selector);
  items.forEach(function(el) {
    if (el.checked)
      specialFeatures.push(+el.id);
  });
  
  return specialFeatures;
};

Ui.getPurposeLegitimateInterests = function() {
  var purposes = [];
  
  var selector = `.layer2_shbnr_cs_${rnd} .purposeleg_switch input`;
  var items = document.querySelectorAll(selector);
  items.forEach(function(el) {
    if (el.checked)
      purposes.push(+el.id);
  });
  
  return purposes;
};

Ui.getVendorConsents = function() {
  var vendor = [];
  
  var selector = `.layer3_shbnr_cs_${rnd} .vendor_switch input`;
  var items = document.querySelectorAll(selector);
  items.forEach(function(el) {
    if (el.checked)
      vendor.push(+el.id);
  });
  
  return vendor;
};

Ui.getVendorLegitimateInterests = function() {
  var vendor = [];
  
  var selector = `.layer3_shbnr_cs_${rnd} .vendorleg_switch input`;
  var items = document.querySelectorAll(selector);
  items.forEach(function(el) {
    if (el.checked)
      vendor.push(+el.id);
  });
  
  return vendor;
};

Ui.getCustomVendorConsents = function() {
  var vendor = [];
  
  var selector = `.layer3_shbnr_cs_${rnd} .customVendor_switch input`;
  var items = document.querySelectorAll(selector);
  items.forEach(function(el) {
    if (el.checked)
      vendor.push(el.id);
  });
  
  return vendor;
}

Ui.setUtils = function(_Utils) {
  Utils = _Utils;
}

module.exports = {
  Ui2: Ui
};