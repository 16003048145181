
var Utils = {
  get: function(src, callback) {
    var request = new XMLHttpRequest();
    request.open('GET', src, true);

    request.onload = function() {
      if (request.status >= 200 && request.status < 400) {
        // Success!
        var resp = request.responseText;
        callback(resp);
      } else {
        // We reached our target server, but it returned an error
        callback('');
      }
    };
    request.onerror = function() {
      // There was a connection error of some sort
      callback('');
    };
    request.send();
  },
  uniqueHash: function() {
    return 's' + (new Date().getTime()).toString(36) + Math.random().toString(36).slice(2);
  },
  removePermanentCookie: function(name) {
    localStorage.removeItem(name);
    document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  },
  setPermanentCookie: function(name, value, ms_to_expire) {
    // il valore del cookie è la data di expire + underscore + il valore passato
    var d = new Date();
    d.setTime(d.getTime() + ms_to_expire);
    var cookievalue = d.getTime() + "_" + value;
    var cookievalue_notime = value;
    var expiredate = d.toUTCString();
    document.cookie = name + '=' + cookievalue_notime + '; expires=' + expiredate + '; path=/';
    
    if (typeof(localStorage) !== "undefined") {
      localStorage.setItem(name, cookievalue);
    }
  },
  getPermanentCookie: function(name) {
    var ck_arr = document.cookie.split(';');
    for (var i = 0; i < ck_arr.length; i++) {
      var ck = ck_arr[i].split('=');
      var ck_name = ck[0].trim();
      if (ck_name == name) {
        var ck_value = ck[1];
        // allinea il localstorage prima di ritornare
        //  (non posso farlo, senza l'informazione del timestamp).
        //if (typeof(localStorage) !== "undefined") {
        //  localStorage.setItem(ck_name, d.getTime() + "_" + ck_value);
        //}
        return ck_value;
      }
    }
    
    if (typeof(localStorage) !== "undefined") {
      if (localStorage[name]) {
        var expire = parseInt(localStorage[name].split("_")[0], 10);
        if (expire > new Date().getTime()) {
          // allinea il cookie prima di ritornare
          var d = new Date();
          d.setTime(expire);
          var expiredate = d.toUTCString();
          document.cookie = name + '=' + localStorage[name].split("_")[1] + '; expires=' + expiredate + '; path=/';
          
          return localStorage[name].split('_').slice(1).join('_');
        } else {
          localStorage.removeItem(name);
        }
      }
    }
    
    return "";
  }
}

module.exports = {
  Utils: Utils
};
